<template>
  <v-list-item
    v-test-id="'list-item-activator-item'"
    :exact="exactPath"
    class="list-item"
    :class="{
      'list-item--draggable': draggable,
      'list-item--active': active
    }"
    :aria-label="item.title"
    :to="item.to || null"
    :disabled="disabled"
    density="compact"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <deck-icon
      v-if="draggable"
      v-test-class="'list-item-drag-icon'"
      name="grip-dots-vertical"
      class="list-item__drag-icon"
    />

    <slot
      name="item-content"
      :item="item"
      :index="index"
    />

    <template
      v-if="item.icon && !itemContentSlot"
      #prepend
    >
      <deck-icon
        class="list-item__content-icon"
        color="var(--z-color-label)"
        size="small"
        fixed-width
        start
        :name="item.icon"
        :disabled="disabled"
      />
    </template>

    <v-list-item-title
      v-if="!itemContentSlot"
      :class="{
        'text-grey': item.inactive,
        [itemTitleTypography]: true
      }"
      :title="item.title || item.text"
    >
      {{ item.title || item.text }}
    </v-list-item-title>

    <template #append>
      <OptionsMenu
        v-if="displayOptionsMenu"
        :show-menu-activator="fixedOptionsMenu || hover"
        :item="item"
        :left="left"
        @menu-activator-click="$emit('menuActivatorClick')"
      >
        <template #prependItems>
          <slot name="optionsMenuPrependItems" />
        </template>
      </OptionsMenu>

      <div
        v-if="activeActions.length > 0"
        class="d-flex align-center g-1 ml-1"
      >
        <template v-for="activeAction in activeActions">
          <deck-chip
            v-if="activeAction.activeIcon"
            :key="activeAction.activeIcon"
            :icon="activeAction.activeIcon"
            :text="activeAction.activeIconTooltip"
            :color="activeAction.activeIconColor"
            :tooltip-props="{
              disabled: !activeAction.activeIconTooltip,
              kind: activeAction.activeIconTooltipKind,
            }"
            class="flex-none"
            size="x-small"
          />
        </template>
      </div>

      <slot
        name="item-actions"
        :item="item"
        :index="index"
      />
    </template>
  </v-list-item>
</template>
<script>
export default {
  name: 'ListItem',
  components: {
    OptionsMenu: defineAsyncComponent(() => import('~/components/options-menu')),
  },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    itemTitleTypography: { type: String, default: 'text-body-2' },
    draggable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    exactPath: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    fixedOptionsMenu: { type: Boolean, default: false },
    hideOptionsMenu: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },
  emits: ['menuActivatorClick'],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    displayOptionsMenu() {
      return !this.hideOptionsMenu && !this.itemActionsSlot && this.item.actions && this.item.actions.length > 0;
    },
    itemContentSlot() {
      return Boolean(this.$slots['item-content']);
    },
    itemActionsSlot() {
      return Boolean(this.$slots['item-actions']);
    },
    activeActions() {
      return this.item?.actions?.filter(action => action.active) || [];
    },
  },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    subMenuItemClicked(item, subMenuItemCallback) {
      item.menuOpen = false;
      subMenuItemCallback();
    },
  },
};
</script>
<style lang="scss">
.list-item {
  --list-item-background-color: transparent;
  --list-item-background-color-highlight: var(--z-color-subtle-shade);
  --list-item-background-color-toggle: var(--z-color-accent-shade);

  min-height: 32px !important;
  padding: 0;

  > .list-item__content {
    align-items: center;
    padding: 0 var(--z-s3);
    transition: 200ms ease;

    > .list-item__content-icon {
      flex: 0 0 auto;
      margin-right: var(--z-s1);
      margin-bottom: 0;
      width: 20px;
    }

    > .v-list-item__title {
      line-height: var(--z-line-height-base);
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  &.sortable-chosen:hover {
    cursor: grabbing;

    > .list-group-activator__drag-icon {
      cursor: grabbing;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .v-list-item__overlay {
    background: var(--list-item-background-color) !important;
    border-style: none !important;
    inset: 0;
    opacity: 1 !important;
    pointer-events: none;
    position: absolute;
    transition: background-color 150ms ease;
  }

  &:hover, &:focus-within {
    .v-list-item__overlay {
      background: var(--list-item-background-color-highlight) !important;
    }
  }

  &.list-item--active {
    background: var(--list-item-background-color-highlight) !important;
  }
}

.list-item__drag-icon {
  border-radius: 4px;
  height: 100%;
  inset: 0;
  min-width: 0 !important;
  opacity: 0;
  position: absolute !important;
  transition: 200ms ease-in-out !important;
  width: 12px;
  z-index: 1;

  &:hover {
    cursor: grab;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    cursor: grabbing;
  }
}

.list-item--draggable:hover .list-item__drag-icon {
  opacity: 1;
}
</style>
